import { createSharedPathnamesNavigation } from 'next-intl/navigation';



// Can be imported from a shared config
// export const locales = ['en', 'zh', 'es', 'ja'];
export const localePrefix = 'as-needed';


export const languages = [
  {
    code: 'en-US',
    locale: 'en',
    language: 'English',
  },
  { 
    code: 'ja-JP',
    locale: 'ja',
    language: '日本語',
  },
  {
    code: 'ko-KR',
    locale: 'ko',
    language: '한국어',
  },
  // {
  //   code: 'de-DE',
  //   locale: 'de',
  //   language: 'Deutsch',
  // },
  // {
  //   code: 'es-ES',
  //   locale: 'es',
  //   language: 'Español',
  // },
  // {
  //   code: 'fr-FR',
  //   locale: 'fr',
  //   language: 'Français',
  // },
  // {
  //   code: 'pt-BR',
  //   locale: 'pt',
  //   language: 'Português',
  // },
  // {
  //   code: 'ru-RU',
  //   locale: 'ru',
  //   language: 'Русский',
  // },
  {
    code: 'zh-CN',
    locale: 'zh',
    language: '简体中文',
  }
  // {
  //   code: 'zh-TW',
  //   locale: 'tw',
  //   language: '繁體中文',
  // },
];

export const locales = languages.map((lang) => lang.locale);



export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({ locales, localePrefix });